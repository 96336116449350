(function() {
  let registerForm = document.querySelector('#register.modal form');

  if (registerForm) {
    let autoConfirmPassword = (event) => {
      passwordConfirmation = registerForm.querySelector('#register_user_password_confirmation');
      passwordConfirmation.value = event.target.value;
    }
  
    registerForm.querySelector('#register_user_password').addEventListener('keyup', autoConfirmPassword);
  }

  let passwordInputs = document.querySelectorAll('input[type="password"]');
  if (passwordInputs) {
    let toggleViewPassword = function(event) {
      let icon = event.currentTarget.querySelector("i");
      let input = event.target.parentElement.parentElement.querySelector('input');

      if (input.type == "password") {
        icon.classList.remove("bi-eye");
        icon.classList.add("bi-eye-slash");

        input.type = "text";
      } else {
        icon.classList.remove("bi-eye-slash");
        icon.classList.add("bi-eye");

        input.type = "password";
      }
    }

    passwordInputs.forEach((input) => {
      let iconButton = input.parentElement.querySelector('.view-password');
      if (!iconButton) {return;}
      iconButton.addEventListener('click', toggleViewPassword);
    });
  }
})();